import React, { useState, useRef, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { SocialIcon } from 'react-social-icons';

import Container from 'react-bootstrap/Container';
import './App.css';
const axios = require('axios')
const orderedEmojis = require('unicode-emoji-json/data-ordered-emoji.json')

const INDEX_URL = "https://dannydeezy.github.io/ordmojis/indexer/lowest-emojis.json"
const GITHUB_URL = "https://github.com/dannydeezy/ordmojis"

const App = () => {
  const [index, setIndex] = useState({})

  useEffect(() => {
    async function loadIndex() {
      const res = await axios.get(`${INDEX_URL}?nocache=${Date.now()}`)
      const newIndex = Object.assign({}, res.data)
      setIndex(newIndex)
    }
    loadIndex()
  }, [index])

  function emojiRow(emoji, i) {
    if (!index.lowestEmojis) return (<></>)
    const style = {}
    if (index.lowestEmojis[emoji]) {
      style.backgroundColor = 'lightgreen'
    }
    return (
      <tr style={style}>
        <td><h3>{emoji}</h3></td>
        <td><a href={`https://dannydeezy.github.io/ordmojis/files/${i + 1}.txt`} target="_blank">{`${i+1}.txt`}</a></td>
        {
          index.lowestEmojis[emoji] ? <>
            <td><a href={`https://ordinals.com/inscription/${index.lowestEmojis[emoji].id}`} target="_blank">{index.lowestEmojis[emoji].id}</a></td>
          </>
          :
          <>
          <td></td>
          </>
        }
      </tr>
    )
  }

  const totalEmojis = orderedEmojis.length
  const numInscribed = Object.keys((index && index.lowestEmojis) || []).length
  return (
    <>
      <Navbar>
        <Container>
          <Navbar.Brand className="flex-row">
            <Nav.Link onClick={() => window.open(GITHUB_URL)}>
              <SocialIcon url={GITHUB_URL} />
            </Nav.Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
    <Container className="text-center">
        <h1 className="py-3">Ordmojis</h1>
        <p className="pb-3">Tracking the first <a href="https://unicode.org/emoji/charts/full-emoji-list.html" target="_blank">emoji</a> bitcoin inscriptions. The latest index is updated regularly <a href={INDEX_URL} target="_blank">here</a></p>
        <p className="pb-3">
          <b>{numInscribed}/{totalEmojis} inscribed</b>
        </p>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Emoji</th>
              <th>File</th>
              <th>First Inscription</th>
            </tr>
          </thead>
          <tbody>
            {
              orderedEmojis.map((emoji, i) => emojiRow(emoji, i))
            }
          </tbody>
        </Table>
    </Container>
    </>
  )
}

export default App;
